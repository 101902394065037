<template>
  <div>
    <div class="type-area">
      <div class="crumbs__content">
        <router-link :to="{ name: 'CooperationCase' }" tag="span" class="crumbs__item__index">合作案例</router-link>
        <span class="crumbs__item__symbol">/</span>
        <span class="crumbs__item__show">{{detail.title}}</span>
      </div>


      <div class="content-div" v-html="detail.content" v-loading="loading" element-loading-text="拼命加载中">
        <!--CooperationCaseDetail-->
      </div>
    </div>
    <div class="relatedToTheCase" v-if="bottomCooperationCase">
      <div class="type-area">
        <div class="TheCaseTitle">相关案例</div>
        <ul class="item-list">
          <li class="item" v-for="(item, index) in theCase" @click="toDetails(item)" :key="index">
            <div class="img-div">
              <img :src="item.img"/>
            </div>
            <!--<div class="title">-->
            <!--<span v-for="(com, ind) in item.computedDigest">-->
            <!--<span class="name">{{com}}</span>-->
            <!--<span class="cat-line" v-if="(item.computedDigest.length-1) != ind"></span>-->
            <!--</span>-->
            <!--</div>-->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { queryById, caseGet, caseRelated } from "@/api/request";

  export default {
    name: "CooperationCaseDetail",
    inject: ["reload"],
    data() {
      return {
        tagName: "",
        detail: {},
        loading: true,
        bottomCooperationCase: false,//  底部相关案例
        theCase: []//相关案例
      };
    },

    created() {
      const { id } = this.$route.query;
      this.getCooperationCaseDetailById({ id });
      //{ pageIndex:1,pageSize:9,tagName:this.tagName,typeCode:'hezuoanli' }
      this.getRelatedCase({ pageIndex: 1, pageSize: 3, id });


    },
    mounted() {

    },
    methods: {
      /**
       * 查询案例详情
       * @param datas { id : 商品id}
       */
      async getCooperationCaseDetailById(id) {
        let { success, result } = await queryById(id);
        if (success) {
          this.detail = result;
          this.loading = false;
        }
      },
      /**
       * 查询相关案例
       * @param datas { pageIndex: 当前页, pageSize: 条数, id : 商品id}
       */
      getRelatedCase(datas) {
        caseRelated(datas).then(res => {
          this.theCase = res.result;
          this.bottomCooperationCase = res.result.length;
        });
      },
      toDetails(item) {
        // this.$router.push({ path: "/case/detail", query: { id:item.id, tagName: this.tagName,title:item.title } });
        this.$router.push({ path: "/case/detail", query: { id: item.id } });
        this.reload();
      }
    }
  };
</script>

<!--#005CD1 #2c3e50-->
<!--14px 60px-->
<style lang="less" scoped>
  .crumbs__content {
    line-height: 60px;
    height: 60px;
    color: #2c3e50;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    .crumbs__item__index {
      &:hover{
        color: #005CD1;
        cursor: pointer;
      }
    }
    .crumbs__item__symbol {
      margin: 0 5px;
    }
    .crumbs__item__show {
      color: #8D9299;
    }
  }

  .content-div {
    min-height: 400px;
    padding-bottom: 80px;
    overflow-x: hidden;

  }

  /deep/ .content-div {
    img {
      width: 100%;
      display: block;
    }
  }

  .relatedToTheCase {
    width: 100%;
    height: 380px;
    background: #f0f2f5;
    .TheCaseTitle {
      height: 18px;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      text-align: center;
      color: #2b2f33;
      line-height: 18px;
      margin: 0px 0 30px 0;
      padding-top: 50px;
    }

    .item-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &:after {
        /* 解决最后一排不足三个不能对齐 */
        content: "";
        width: 32%;
      }
      .item {
        background: #fff;
        width: 32%;
        height: 217px;
        margin-bottom: 24px;
        &:hover {
          box-shadow: 0px 10px 18px 0px rgba(141, 149, 159, 0.23);
          cursor: pointer;
        }
        .img-div {
          width: 100%;
          height: 217px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          margin-top: 16px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          /*text-align: left;*/
          color: #5c6066;
          line-height: 19px;
          display: flex;

          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          overflow: hidden;
          & > span {
            flex-shrink: 0;
          }
          .name {
            display: inline-block;
            padding: 0 12px;
          }
          .cat-line {
            display: inline-block;
            width: 1px;
            height: 9px;
            background: #d9dbdd;
          }
        }
      }
    }

  }
</style>
